.section1 {
    padding: 10px;
    background-color: #789CA5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowheading {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.inputfield {
    width: 80px;
    border-radius: 80px;
    font-size: 14px;
}

.inputfieldsearch {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none !important;
    font-size: 14px;
}

.inputfieldsearch:focus {
    border: none !important;
}

.searchbar {
    width: 350px;
    border: 1px solid #DDDCDC;
    border-radius: 30px;
}

.searchButton {
    background-color: #FFFFFF !important;
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 12px;
}

.searchButton svg path {
    fill: #545454;
}

.tableContainer tr {
    border-bottom: 1px solid #CACACA !important;
}

.tablesection th,
.tablesection td {
    box-shadow: none;
}

.userprofile {
    height: 32px;
    width: 32px;
    border-radius: 50px;
    color: #769AA3;
}

.content {
    font-size: 24px;
    margin-bottom: 0 !important;
}

.tabledata {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
}

.tabledata td {
    vertical-align: middle;
}

.tableContainer div {
    border-radius: 12px;
}

.tableContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.view {
    color: #5B93FF;
    font-size: 22px;
    cursor: pointer;
}

.plusbtn,
.plusbtn:hover {
    width: 30px;
    height: 30px;
    background-color: #789CA5;
    border-radius: 50%;
    padding: 0;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section1 {
    padding: 10px;
    background-color: #789CA5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowheading {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.tableContainer tr {
    border-bottom: 1px solid #CACACA !important;
}

.tablesection th,
.tablesection td {
    box-shadow: none;
}


.tabledata {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
}

.delete {
    color: #E71D36;
    font-size: 20px;
    cursor: pointer;
}

.tabledata td {
    vertical-align: middle;
}

.tableContainer div {
    border-radius: 12px;
}

.tableContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
}

@media only screen and (max-width: 1440px) {
    .tableContainer table {
        min-width: 1500px !important;
    }

    .tabledata {
        font-size: 16px;
    }

    .button svg {
        height: 25px;
        width: 25px;
    }
}

@media only screen and (max-width: 425px) {
    .rowheading {
        font-size: 14px;
    }

    .tabledata td {
        padding: 0.5rem !important;
    }

    .tabledata {
        font-size: 12px;
    }

    .tableContainer table {
        min-width: 800px !important;
    }

    .searchbar {
        width: auto;
    }
}