.section1 {
    padding: 10px;
    background-color: #789CA5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowheading {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.inputfieldsearch {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none !important;
    font-size: 14px;
}

.inputfieldsearch:focus {
    border: none !important;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
}

.searchbar {
    width: auto;
    border: 1px solid #DDDCDC;
    border-radius: 30px;
}

.searchButton {
    background-color: #FFFFFF !important;
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 12px;
}

.searchButton svg path {
    fill: #545454;
}

.cacelbutton,
.cacelbutton:hover,
.cacelbutton:focus {
    border-radius: 30px;
    font-size: 14px;
    border: 1px solid #789CA5;
    background-color: #FFFFFF;
    color: #000;
    width: 120px !important;
}

.tableContainer tr {
    border-bottom: 1px solid #CACACA !important;
}

.tablesection th,
.tablesection td {
    box-shadow: none;
}

.content {
    font-size: 12px;
    margin-bottom: 0 !important;
}

.tabledata {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
}

.edit {
    color: #5B93FF;
    font-size: 20px;
}

.delete {
    color: #E71D36;
    font-size: 20px;
}

.play {
    color: #789CA5;
    font-size: 20px;
}

.tabledata td {
    vertical-align: middle;
}

.tableContainer div {
    border-radius: 12px;
}

.tableContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.button,
.button:hover,
.button:focus,
.button:active {
    background-color: #789CA5;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
}

.active {
    color: #15803D;
    background-color: #DCFCE7;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
}

.inactive {
    color: #E71D36;
    background-color: #F8C8CB;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
}

@media only screen and (max-width: 1440px) {
    .button {
        padding: 5px;
    }

    .tabledata {
        font-size: 16px;
    }

    .button svg {
        height: 25px;
        width: 25px;
    }

    .tableContainer table {
        min-width: 1500px !important;
    }
}

@media only screen and (max-width: 1300px) {
    .button {
        padding: 5px;
        font-size: 16px;
    }

}

@media only screen and (max-width: 768px) {

    .button,
    .button:hover,
    .button:focus,
    .button:active {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .button svg {
        height: 20px;
        width: 20px;
    }
}


@media only screen and (max-width: 425px) {
    .rowheading {
        font-size: 14px;
    }

    .tabledata td {
        padding: 0.5rem !important;
    }

    .tabledata {
        font-size: 12px;
    }

    .tableContainer table {
        min-width: 800px !important;
    }

    .button {
        font-size: 14px;
    }
}
