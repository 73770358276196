.activeTab {
    color: #769AA3;
    border-bottom: 1px solid #769AA3;
}

.section1 {
    padding: 10px;
    background-color: #789CA5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowheading {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.inputfieldsearch {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none !important;
    font-size: 14px;
}

.inputfieldsearch:focus {
    border: none !important;
}

.searchbar {
    width: 350px;
    border: 1px solid #DDDCDC;
    border-radius: 30px;
}

.searchButton {
    background-color: #FFFFFF !important;
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 12px;
}

.searchButton svg path {
    fill: #545454;
}

.tableContainer tr {
    border-bottom: 1px solid #CACACA !important;
}

.tablesection th,
.tablesection td {
    box-shadow: none;
}

.content {
    font-size: 12px;
    margin-bottom: 0 !important;
}

.tabledata {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
}

.edit {
    color: #5B93FF;
    font-size: 20px;
    cursor: pointer;
}

.view {
    color: #15803D;
    font-size: 22px;
    cursor: pointer;
}

.delete {
    color: #E71D36;
    font-size: 20px;
    cursor: pointer;
}

.play {
    color: #789CA5;
    font-size: 20px;
}

.tabledata td {
    vertical-align: middle;
}

.tableContainer div {
    border-radius: 12px;
}

.tableContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.button,
.button:hover,
.button:focus,
.button:active {
    background-color: #789CA5;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
}

.buttontoggle {
    padding: 0.5rem;
    min-width: 125px;
}

.buttontoggle a:active {
    background-color: transparent;
    color: #000;
}

.buttontoggle a:hover {
    background-color: transparent;
    color: #000;
}

.active {
    color: #15803D;
    background-color: #DCFCE7;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
}

.inactive {
    color: #E71D36;
    background-color: #F8C8CB;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.cacelbutton,
.cacelbutton:hover,
.cacelbutton:focus {
    border-radius: 30px;
    font-size: 14px;
    border: 1px solid #789CA5;
    background-color: #FFFFFF;
    color: #000;
    width: 120px !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
}

.modalcontent {
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #B8B8B8;
    border-radius: 80px;
}

.modallargecontent {
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #B8B8B8;
    border-radius: 10px;
}

.modalheading {
    font-size: 14px;
    font-weight: 500;
}

@media only screen and (max-width: 1440px) {

    .button {
        padding: 5px;
    }

    .tabledata {
        font-size: 16px;
    }

    .button svg {
        height: 25px;
        width: 25px;
    }

    .tableContainer table {
        min-width: 1500px !important;
    }
}

@media only screen and (max-width: 1300px) {
    .button {
        padding: 5px;
        font-size: 16px;
    }

}

@media only screen and (max-width: 768px) {

    .button,
    .button:hover,
    .button:focus,
    .button:active {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .button svg {
        height: 20px;
        width: 20px;
    }
}

.inputfield {
    width: 500px !important;
    border-radius: 80px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.error {
    margin-bottom: 0 !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.cancelbutton,
.cancelbutton:hover,
.cancelbutton:focus,
.cancelbutton:active {
    border: 1px solid #DDDCDC;
    border-radius: 30px;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #000000;
    width: 120px !important;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.formsection {
    width: 75%;
}

@media only screen and (max-width: 1200px) {
    .inputfield {
        width: 300px !important;
    }
    .formsection {
        width: 100%;
    }
    
}

@media only screen and (max-width: 996px) {
    .formsection {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .searchbar {
        width: auto;
    }
}

@media only screen and (max-width: 425px) {
    .rowheading {
        font-size: 14px;
    }

    .tabledata td {
        padding: 0.5rem !important;
    }

    .tabledata {
        font-size: 12px;
    }

    .tableContainer table {
        min-width: 800px !important;
    }
}