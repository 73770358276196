.inputfieldsearch {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none !important;
    font-size: 14px;
}

.inputfieldsearch:focus {
    border: none !important;
}

.searchbar {
    width: 350px;
    border: 1px solid #DDDCDC;
    border-radius: 30px;
}

.searchButton {
    background-color: #FFFFFF !important;
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 12px;
}

.searchButton svg path {
    fill: #545454;
}

.button,
.button:hover,
.button:focus,
.button:active {
    background-color: #789CA5;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
}

.accordionitem {
    border: 1px solid #DDDCDC !important;
    border-radius: 10px !important;
}

.accordionheader button {
    border-radius: 10px !important;
    font-size: 15px;
    font-weight: 500;
}

.accordionbody {
    border-top: 2px solid #D9D9D9;
    padding: 1rem 0 0 0 !important;
    margin: 0 1rem 1rem 1.25rem;
    font-size: 14px;
    font-weight: 500;
}

/* .accordionheader button::after {
    background-image: url('../../assets/arrow.png') !important;
}

.accordionheader button:not(.accordionitem div)::after {
    background-image: url('../../assets/arrow.png') !important;
} */

.edit {
    color: #5B93FF;
    font-size: 20px;
    cursor: pointer;
}

.delete {
    color: #E71D36;
    font-size: 20px;
    cursor: pointer;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.cacelbutton,
.cacelbutton:hover,
.cacelbutton:focus {
    border-radius: 30px;
    font-size: 14px;
    border: 1px solid #789CA5;
    background-color: #FFFFFF;
    color: #000;
    width: 120px !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
}

.activeTab {
    color: #769AA3;
    border-bottom: 1px solid #769AA3;
}

@media only screen and (max-width: 425px) {
    .searchbar {
        width: auto;
    }
}