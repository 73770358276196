.card {
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8px;
}

.imageContainer {
    position: relative;
    height: 0;
    padding-bottom: 150%;
    border-radius: 15px;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.overlay {
    position: absolute;
    bottom: 10%;
    left: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    text-align: center;
}

.author {
    text-align: end;
    font-size: 14px;
}

.quote {
    margin: 0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.buttonContainer {
    text-align: center;
    padding: 10px;
}

.button {
    background-color: #607d8b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.activeTab {
    color: #769AA3;
    border-bottom: 1px solid #769AA3;
}

@media only screen and (max-width: 1440px) {
    .quote {
        font-size: 14px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .overlay {
        bottom: 5%;
    }
}