.content {
    font-size: 14;
    font-weight: 500;
    color: #8A92A6;
    margin-bottom: 0.5rem !important;
}

.heading {
    font-size: 21px;
    font-weight: 600;
}

.count1 {
    height: 68px;
    width: 68px;
    background-color: #16A7E94D;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count2 {
    height: 68px;
    width: 68px;
    background-color: #FFCC744D;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count3 {
    height: 68px;
    width: 68px;
    background-color: #F157764D;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section {
    height: 100%;
    align-items: center;
    padding: 18px;
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 30px 0px #1126920D;
    border-radius: 8px;
    gap: 0.5rem;
}

@media only screen and (max-width: 1400px) {
    .progress {
        padding: 10px;
    }
}