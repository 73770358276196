.inputfield {
    width: 400px !important;
    border-radius: 80px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.imagedisplay {
    width: 400px !important;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.error {
    margin-bottom: 0 !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.cancelbutton,
.cancelbutton:hover,
.cancelbutton:focus,
.cancelbutton:active {
    border: 1px solid #DDDCDC;
    border-radius: 30px;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #000000;
    width: 120px !important;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.formsection {
    width: 75%;
}

@media only screen and (max-width: 1200px) {
    .inputfield {
        width: 300px !important;
    }
    .formsection {
        width: 100%;
    }
    
}

@media only screen and (max-width: 996px) {
    .formsection {
        width: 100%;
    }
}