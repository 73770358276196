.inputfieldselect {
    border-radius: 80px;
    width: fit-content;
    font-size: 14px;
}

.inputfieldselect:focus {
    box-shadow: none !important;
    border: 1px solid rgba(165, 165, 165, 0.50) !important;
}

.inputfieldselect div {
    min-height: unset;
    box-shadow: none;
}

.inputfieldselect div:hover {
    box-shadow: none;
}

.inputfieldselect span {
    width: 0;
}

.inputfieldselect div div div {
    color: #000000;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
}