@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

.row {
  --bs-gutter-x: 0 !important;
}

body {
  font-family: 'Nunito', sans-serif;
  background-color: #EAF2F2;
}

:root .table {
  --bs-table-striped-bg: #FFFBED !important;
  --bs-table-hover-bg: #EED378 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: auto;
}

/* navbar and sidebar */

.navbar-classic .navbar-right-wrap .list-group-item {
  padding: 1rem 1.25rem;
}

.navbar-classic .nav-badge {
  position: absolute;
  top: -9px;
  right: 3px;
}

/* Responsive breakpoints */
@media (min-width: 1200px) {
  .navbar-classic .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar-classic .dropdown-menu-start {
    right: auto;
    left: 0;
  }

  .navbar-classic .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }

  .navbar-classic .dropdown:hover>.dropdown-menu,
  .navbar-classic .dropdown-submenu:hover>.dropdown-menu {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 992px) {
  .navbar-classic .navbar-nav .dropdown-menu {
    margin: 0px;
  }

  .navbar-classic .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu {
    box-shadow: none;
    margin: 0;
  }

  .navbar-classic .nav-top-wrap {
    flex-direction: row;
  }

  .navbar-classic .nav-top-wrap .dropdown {
    position: static;
  }

  .navbar-classic .nav-top-wrap .dropdown-menu {
    position: absolute;
    margin: 0.25rem 0.75rem !important;
  }

  .navbar-classic .nav-top-wrap .dropdown-menu .dropdown-submenu .dropdown-menu {
    position: relative;
    top: -7px !important;
    padding: 0px !important;
  }
}

/* Navbar Vertical */
.navbar-vertical {
  margin-left: -17rem;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 260px;
  padding: 0rem;
  background-color: #FFFFFF;
  border-right: 1px solid #CACACA;
  transition: margin 0.25s ease-out;
}

.navbar-vertical .slimScrollDiv,
.navbar-vertical .nav-scroller {
  height: 100% !important;
  overflow-y: auto;
}

.navbar-vertical .navbar-brand {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0px;
  display: block;
}

.navbar-vertical .navbar-brand img {
  height: 112px;
}

.navbar-vertical .navbar-heading {
  color: #9e9e9e;
  padding: 1rem 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
  font-weight: 700;
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  color: #9e9e9e;
  font-weight: 500;
  line-height: 1.8;
  transition: all 0.5s;
  white-space: nowrap;
}

.navbar-vertical .navbar-nav .nav-item .nav-link:hover {
  color: #eeeeee;
}

.navbar-vertical .navbar-nav .nav-item .nav-link.active {
  color: #eeeeee;
}

.navbar-vertical .navbar-nav .nav-item .nav-link .nav-icon {
  opacity: 0.6;
  font-size: 0.875rem;
  transition: all 0.5s;
}

.navbar-vertical .navbar-nav .nav-item .nav-link[data-bs-toggle="collapse"]::after {
  display: block;
  content: "";
  margin-left: auto;
  transition: 0.5s ease;
  position: absolute;
  right: 1.5rem;
  height: 24px;
  width: 24px;
  filter: brightness(0) invert(1);
  transform: scale(0.7);
}

.navbar-vertical .navbar-nav .nav-item.active .nav-link[data-bs-toggle="collapse"]::after {
  transform: rotate(180deg);
}

/* Header */
.header .navbar #nav-toggle {
  font-size: 20px;
  color: #757575;
  line-height: 0.5;
}

.header .navbar .navbar-nav {
  display: flex;

  @media (max-width: 576px) {
    flex-direction: row;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: row;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    flex-direction: row;
  }
}

/* Dashboard wrapper */
#db-wrapper {
  overflow-x: hidden;
  display: flex;
}

/* Dashboard page content */
#page-content {
  min-height: 100vh;
  min-width: 100vw;
  margin-left: 16.2rem;
  transition: margin 0.25s ease-out;
}

/* Dashboard navbar vertical toggled */
#db-wrapper.toggled .navbar-vertical {
  margin-left: 0;
}

/* Dashboard page content toggled */
#db-wrapper.toggled #page-content {
  margin-left: 0;
}

#db-wrapper.toggled #page-content .inner-wrapper-sticky {
  left: 20px !important;
}

#db-wrapper.toggled #page-content .inner-wrapper-sticky .setting-nav {
  width: 354px;

  @media (max-width: 1024px) {
    width: 204px;
  }
}

/* Media query for layout */
@media (max-width: 576px) {
  #page-content {
    margin-left: 0;
  }

  #db-wrapper.toggled #page-content {
    margin-left: 15.6875rem;
  }
}

@media (min-width: 768px) {
  .navbar-vertical {
    margin-left: 0;
  }

  #page-content {
    min-width: 0;
    width: 100%;
  }

  #db-wrapper.toggled .navbar-vertical {
    margin-left: -16rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #page-content {
    margin-left: 0;
  }

  #db-wrapper.toggled #page-content {
    margin-left: 15.6875rem;
  }
}

/* Authentication wrapper */
.authentication_wrapper {
  background-color: #0f506d;
}

.nav-item {
  list-style: none !important;
}

/* button css */
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* form control */
.form-control:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

select {
  outline: 0 !important;
}

/* modal */
.modal-content {
  border-radius: 10px;
  border: 0;
  padding: 18px;
}

.modal-header {
  padding: 0;
}

.modal-footer {
  padding: 0;
}

/* accordian  */

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background-color: unset !important;
}

/* input {
  -moz-appearance: textfield;
  appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

.leaflet-container {
  height: 50vh;
}

.react-calendar__month-view__weekdays {
  color: #000;
  font-size: 10px;
  font-weight: 500;
}

.react-calendar__tile--now {
  background: #282c34 !important;
  border-radius: 50px;
  color: #FFFFFF;
}

.react-calendar__tile--now:hover {
  color: #000;
}

.react-calendar__tile--active:enabled:hover {
  background: unset !important;
  color: #000 !important;
}

.react-calendar__tile:enabled:hover {
  background-color: unset;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.react-calendar__navigation button:enabled:hover {
  background-color: unset !important;

}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: unset !important;
}

.accordion-button:not(.collapsed) {
  color: unset;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M8 12L2 4h12L8 12z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  content: "";
}

/* Hide the remaining player controls */

.form-check-input:checked {
  background-color: #789CA5;
  border-color: #789CA5;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: unset;
}

.btn-close:focus {
  box-shadow: none;
}

.react-audio-player {
  width: 100%;
}

.maindiv {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.ql-size-small {
  font-size: 0.75em;
  /* Small size */
}

.ql-size-large {
  font-size: 1.5em;
  /* Large size */
}

.ql-size-huge {
  font-size: 2.5em;
  /* Huge size */
}

.ql-snow {
  border: 0 !important;
}

.ql-toolbar {
  background-color: #F4F4F4;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  border: 0;
  color: #0d6efd;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.invalid-feedback {
  display: inline !important;
}

@media only screen and (max-width: 1200px) {
  .navbar-vertical .navbar-brand {
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;
  }

  .header .navbar {
    padding: 10px 30px 6px 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .header .navbar {
    padding: 10px 20px 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-vertical {
    max-width: 250px;
  }

  .maindiv {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .header .navbar {
    padding: 10px 10px 0 10px;
  }

  .maindiv {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}