.paginationContainer {
    text-align: end;
    display: flex;
    justify-content: space-between;

}

.pagination {
    list-style: none;
    display: flex;
    font-size: 18px;
    align-items: center;
    padding-left: 0 !important;
    width: 100%;
    justify-content: center;
}

.pagination li {
    margin: 6px;
}

.next {
    margin-left: auto !important;
    margin-right: 30px !important;
    border: 1px solid #CCCCCC;
    border-radius: 33px;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 0px;
    text-align: center;
}

.previous {
    margin-right: auto !important;
    margin-left: 30px !important;
    border: 1px solid #CCCCCC;
    border-radius: 33px;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 0px;
    text-align: center;
}

.activepage {
    border: 1px solid #CCCCCC;
    border-radius: 50px;
}

.previous a {
    color: #000000;
    padding: 10px 15px !important;
}

.next a {
    color: #000000;
    padding: 10px 30px !important;
}

.pages {
    height: 45px;
    width: 45px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable {
    cursor: not-allowed;
    color: #CCCCCC !important;
}

@media only screen and (max-width: 500px) {
    .pagination {
        font-size: 14px;
    }

    .previous {
        margin-left: 5px !important;
        font-size: 13px;
        width: 90px;
    }

    .next {
        font-size: 13px;
        width: 90px;
        margin-right: 5px !important;
    }

    .previous a {
        padding: 10px 8px !important;
    }

    .next a {
        padding: 10px 10px !important;
    }

    .pagination li {
        margin: 4px;
    }

    .pages {
        height: 30px;
        width: 30px;
    }
}