.inputfield {
    width: 400px !important;
    border-radius: 80px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.imagedisplay {
    width: 400px !important;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.fileupload,
.fileupload:hover,
.fileupload:focus {
    width: 400px !important;
    border-radius: 80px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #000000;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.error {
    margin-bottom: 0 !important;
}

.inputfieldtextarea {
    width: 400px;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.inputfieldselect {
    width: 400px;
    border-radius: 80px;
    font-size: 14px;
}

.inputfieldselect:focus {
    box-shadow: none !important;
    border: 1px solid rgba(165, 165, 165, 0.50) !important;
}

.inputfieldselect div {
    min-height: unset;
    box-shadow: none;
}

.inputfieldselect div:hover {
    box-shadow: none;
}

.inputfieldselect span {
    width: 0;
}

.inputfieldselect div div div {
    color: #000000;
    font-size: 14px;
}

.cancelbutton,
.cancelbutton:hover,
.cancelbutton:focus,
.cancelbutton:active {
    border: 1px solid #DDDCDC;
    border-radius: 30px;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #000000;
    width: 120px !important;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #789CA5;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.filename {
    font-size: 14px;
}

.content {
    font-size: 13px;
    color: #545454;
}

.formsection {
    width: 100%;
}

@media only screen and (max-width: 1200px) {

    .inputfield,
    .inputfieldselect,
    .inputfieldtextarea,
    .fileupload {
        width: 300px !important;
    }

}

@media only screen and (max-width: 996px) {

    .inputfield,
    .inputfieldselect,
    .inputfieldtextarea,
    .fileupload {
        width: 80% !important;
    }

}

@media only screen and (max-width: 425px) {

    .inputfield,
    .inputfieldselect,
    .inputfieldtextarea,
    .fileupload {
        width: 100% !important;
    }

}